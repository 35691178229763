(function () {
    'use strict';
    angular.module("serviceboxApp", [
        'leaflet-directive',
        'mgcrea.ngStrap',
        'ngAnimate',
        'angularUtils.directives.dirPagination',
        'rzModule'
    ]).config(["$logProvider", "paginationTemplateProvider", function ($logProvider, paginationTemplateProvider) {
        $logProvider.debugEnabled(false);
        paginationTemplateProvider.setPath('../app/pagination/dirPagination.tpl.html');



    }]);
})();

(function() {
'use strict';

    angular
        .module('serviceboxApp')
        .controller('emergencyCtrl', emergencyCtrl);

    emergencyCtrl.$inject = ['$scope','contactsService'];
    function emergencyCtrl($scope,contactsService) {
        var vm = this;
        vm.loading=false;
        vm.emergencyContatcs=[];
        

        vm.init=function(){
           getEmergencyContacts();

        };
        
        function getEmergencyContacts(){
                  vm.loading=true;
            contactsService.getEmergencyContacts({
                success: function (emergencyContactsFromServer) {
                    vm.emergencyContatcs = emergencyContactsFromServer.data;
                    vm.loading=false;

                },
                error: function () {
                    vm.loading=false;
                }
            });
        }
    }
})();
(function () {
    'use strict';
    angular.module('serviceboxApp').controller('controlCtrl', controlCtrl);

    controlCtrl.$inject = ['$scope', '$rootScope'];
    function controlCtrl($scope, $rootScope) {
        $scope.viewTypeLabel = 'TILE VIEW';
        $scope.attractionMapView = true;
        $scope.filtersLabel = 'HIDE FILTERS';
        $scope.showRoutesLabel = 'SHOW ROUTES';
        $scope.filterATMLabel = 'SHOW BRANCHES ONLY';
        $scope.showFilters = true;
        $scope.groupMarkers = true;


        //GENERAL CONTROLS
        $scope.enableSearch = function () {
            $rootScope.$broadcast('enableSearch');
        };


        $scope.toggleMarkerGrouping = function () {
            $scope.groupMarkers = !$scope.groupMarkers;
            $rootScope.$broadcast('toggleMarkerGrouping')
        };

        //DISTANCE SEARCH CONTROLS
        $scope.showDistanceFilter = function () {
            $rootScope.$broadcast('showDistanceFilter')
        };

        //BANKS CONTROLSS
        $scope.mapView = true;
        $scope.atmCardView = false;
        $scope.branchCardView = false;
        $scope.showATMs = true;
        $scope.photoGalleryLoading = false;
        $scope.switchView = function () {
            $scope.attractionMapView = !$scope.attractionMapView;
            $rootScope.$broadcast('switchView');
        };
        $scope.toggleFilters = function () {
            $scope.showFilters ? $scope.filtersLabel = 'SHOW FILTERS' : $scope.filtersLabel = 'HIDE FILTERS';
            $scope.showFilters = !$scope.showFilters;
            $rootScope.$broadcast('toggleFilters', {showFilters: $scope.showFilters});
        };
        $scope.searchAttractions = function () {
            $rootScope.$broadcast('searchAttractionsClick');
        };
        $scope.showRouteSelection = function () {
            $rootScope.$broadcast('toggleRouteSelection');
        };
        $scope.showCategorySelection = function () {
            $rootScope.$broadcast('toggleCategorySelection');
        };
        $scope.routesToggle = function () {
            if ($scope.showRoutesLabel === 'HIDE ROUTES') {
                $scope.showRoutesLabel = 'SHOW ROUTES'
            }
            else {
                $scope.showRoutesLabel = 'HIDE ROUTES'
            }
            $rootScope.$broadcast('toggleRoutes');
        };
        $scope.activeTouristRoute = function (routeId) {

            $rootScope.$broadcast('closeAttractionDetailsModal');
            $rootScope.$broadcast('activeRoute', {'routeId': routeId});
        };


        // BANK VIEW CONTROLS  switchToAtmView
        $scope.toggleATMVisibility = function () {
            if ($scope.filterATMLabel === 'SHOW BRANCHES ONLY') {
                $scope.filterATMLabel = 'INCLUDE ATMS';
            }
            else {
                $scope.filterATMLabel = 'SHOW BRANCHES ONLY';
            }
            $scope.showATMs = !$scope.showATMs;
            $rootScope.$broadcast('toggleATMs');
        };
        $scope.switchToAtmView = function () {
            $scope.mapView = false;
            $scope.atmCardView = true;
            $scope.branchCardView = false;
            $rootScope.$broadcast('switchToAtmView');
        };
        $scope.switchToBranchView = function () {
            $scope.mapView = false;
            $scope.atmCardView = false;
            $scope.branchCardView = true;
            $rootScope.$broadcast('switchToBranchView');
        };
        $scope.switchToBankMapView = function () {
            $scope.mapView = true;
            $scope.atmCardView = false;
            $scope.branchCardView = false;
            $rootScope.$broadcast('switchToMapView');
        };

        //MOBILE MONEY AGENTS
        $scope.mobileMoneyAgentsMapView = true;

        $scope.switchMobileMoneyAgentsView = function () {
            $scope.mobileMoneyAgentsMapView = !$scope.mobileMoneyAgentsMapView;
            $rootScope.$broadcast('switchMobileMoneyAgentsView');
        }


    }
})();
'use strict';
angular.module('serviceboxApp').service('TourismRestServices', ["$http", "$q", function ($http, $q) {
    var baseUri = "/api/tourism/",
        tourismService = {};


    tourismService.getAttractionDetails = function (attractionId,callbacks) {
        var df = $q.defer();
        $http.get(baseUri + 'attractions/'+attractionId+'/details', {cache: true}).success(function (responseData) {
            if (callbacks && callbacks.success) {
                callbacks.success(responseData);
            }
            df.resolve(responseData);
        }).error(function () {
            if (callbacks && callbacks.error) {
                callbacks.error("An error occurred while fetching attraction");
            }
            df.reject();
        });
    };
    tourismService.getAttractionRoutes = function (attractionId,callbacks) {
        var df = $q.defer();
        $http.get(baseUri + 'attractions/'+attractionId+'/routes', {cache: true}).success(function (responseData) {
            if (callbacks && callbacks.success) {
                callbacks.success(responseData);
            }
            df.resolve(responseData);
        }).error(function () {
            if (callbacks && callbacks.error) {
                callbacks.error("An error occurred while fetching attraction routes");
            }
            df.reject();
        });
    };
    tourismService.getAttractionsCategories = function (callbacks) {
        var df = $q.defer();
        $http.get(baseUri + 'attractions/categories', {cache: true}).success(function (responseData) {
            if (callbacks && callbacks.success) {
                callbacks.success(responseData);
            }
            df.resolve(responseData);
        }).error(function () {
            if (callbacks && callbacks.error) {
                callbacks.error("An error occurred while fetching categories");
            }
            df.reject();
        });
    };    tourismService.getAttractionsCategories = function (callbacks) {
        var df = $q.defer();
        $http.get(baseUri + 'attractions/categories', {cache: true}).success(function (responseData) {
            if (callbacks && callbacks.success) {
                callbacks.success(responseData);
            }
            df.resolve(responseData);
        }).error(function () {
            if (callbacks && callbacks.error) {
                callbacks.error("An error occurred while fetching categories");
            }
            df.reject();
        });
    };
    tourismService.getAttractionViews = function (attractionId,callbacks) {
        var df = $q.defer();
        $http.get(baseUri + 'attractions/'+attractionId+'/views', {cache: false}).success(function (responseData) {
            if (callbacks && callbacks.success) {
                callbacks.success(responseData);
            }
            df.resolve(responseData);
        }).error(function () {
            if (callbacks && callbacks.error) {
                callbacks.error("An error occurred while fetching attraction views");
            }
            df.reject();
        });
    };
    tourismService.getAttractionPhotoGallery = function (attractionId, callbacks) {
        var df = $q.defer();
        $http.get(baseUri + 'attractions/' + attractionId + '/photo-gallery', {cache: true}).success(function (responseData) {
            if (callbacks && callbacks.success) {
                callbacks.success(responseData);
            }
            df.resolve(responseData);
        }).error(function () {
            if (callbacks && callbacks.error) {
                callbacks.error("An error occurred while fetching attraction photo gallery");
            }
            df.reject();
        });
    };
    function prepareFilters(filters) {
        var query = 'attractions';
        if (filters && filters.categories && filters.categories.length > 0) {
            if (query.length > 11) {
                query += '&category=' + filters.categories.join();
            }
            else {
                query += '?category=' + filters.categories.join();
            }


        }
        if (filters && filters.districts) {
            if (query.length > 11) {
                query += '&district=' + filters.districts;
            }
            else {
                query += '?district=' + filters.districts;
            }


        }
        if (filters && filters.routes) {
            if (query.length > 11) {
                query += '&route=' + filters.routes;
            }
            else {
                query += '?route=' + filters.routes;
            }


        }

        return query;
    }

    tourismService.getAttractions = function (filters, callbacks) {
        var query = prepareFilters(filters);

        var df = $q.defer();
        $http.get(baseUri + query,{cache: true}).success(function (responseData) {
            if (callbacks && callbacks.success) {
                callbacks.success(responseData);
            }
            df.resolve(responseData);
        }).error(function () {
            if (callbacks && callbacks.error) {
                callbacks.error("An error occurred while fetching attractions");
            }
            df.reject();
        });
    };
    tourismService.getLowlandsRoute = function (callbacks) {
        var df = $q.defer();
        $http.get('../data/lowlandsSelfDrive.json', {cache: true}).success(function (data) {
            if (callbacks && callbacks.success) {
                callbacks.success(data);
            }
            df.resolve(data);

        });
    };
    tourismService.getHighlandsSkiRoute = function (callbacks) {
        var df = $q.defer();
        $http.get('../data/highlandsSkiRoute.json', {cache: true}).success(function (data) {
            if (callbacks && callbacks.success) {
                callbacks.success(data);
            }
            df.resolve(data);

        });
    };
    tourismService.getMountain4x4Route = function (callbacks) {
        var df = $q.defer();
        $http.get('../data/mountainBikingRoute.json', {cache: true}).success(function (data) {
            if (callbacks && callbacks.success) {
                callbacks.success(data);
            }
            df.resolve(data);

        });
    };
    tourismService.getMountain4x4RouteP1 = function (callbacks) {
        var df = $q.defer();
        $http.get('../data/mountainBikingRouteP1.json', {cache: true}).success(function (data) {
            if (callbacks && callbacks.success) {
                callbacks.success(data);
            }
            df.resolve(data);

        });
    };
    tourismService.getMountain4x4RouteP2 = function (callbacks) {
        var df = $q.defer();
        $http.get('../data/mountainBikingRouteP2.json', {cache: true}).success(function (data) {
            if (callbacks && callbacks.success) {
                callbacks.success(data);
            }
            df.resolve(data);

        });
    };
    tourismService.getSouthernRusticRoute = function (callbacks) {
        var df = $q.defer();
        $http.get('../data/southernRusticRoute.json', {cache: true}).success(function (data) {
            if (callbacks && callbacks.success) {
                callbacks.success(data);
            }
            df.resolve(data);

        });
    };
    tourismService.getQuthingValleyP1Route = function (callbacks) {
        var df = $q.defer();
        $http.get('../data/quthingP1Route.json', {cache: true}).success(function (data) {
            if (callbacks && callbacks.success) {
                callbacks.success(data);
            }
            df.resolve(data);

        });
    };
    tourismService.getQuthingValleyP2Route = function (callbacks) {
        var df = $q.defer();
        $http.get('../data/quthingP2Route.json', {cache: true}).success(function (data) {
            if (callbacks && callbacks.success) {
                callbacks.success(data);
            }
            df.resolve(data);

        });
    };
    tourismService.getQuthingValleyP3Route = function (callbacks) {
        var df = $q.defer();
        $http.get('../data/quthingP3Route.json', {cache: true}).success(function (data) {
            if (callbacks && callbacks.success) {
                callbacks.success(data);
            }
            df.resolve(data);

        });
    };
    tourismService.getHighlandsCircularRoute = function (callbacks) {
        var df = $q.defer();
        $http.get('../data/highlandsCircularRoute.json', {cache: true}).success(function (data) {
            if (callbacks && callbacks.success) {
                callbacks.success(data);
            }
            df.resolve(data);

        });
    };
    tourismService.getKingMoshoeshoeHistoricalRoute = function (callbacks) {
        var df = $q.defer();
        $http.get('../data/kingMoshoeshoeHistoricalRoute.json', {cache: true}).success(function (data) {
            if (callbacks && callbacks.success) {
                callbacks.success(data);
            }
            df.resolve(data);

        });
    };
    tourismService.getKingCentralPonyTrekRoute = function (callbacks) {
        var df = $q.defer();
        $http.get('../data/centralPonyTrekRoute.json', {cache: true}).success(function (data) {
            if (callbacks && callbacks.success) {
                callbacks.success(data);
            }
            df.resolve(data);

        });
    };
    tourismService.getKingCentralPonyTrekP1Route = function (callbacks) {
        var df = $q.defer();
        $http.get('../data/centralPonyTrekRouteP1.json', {cache: true}).success(function (data) {
            if (callbacks && callbacks.success) {
                callbacks.success(data);
            }
            df.resolve(data);

        });
    };
    return tourismService;
}]);
(function () {
    'use strict';
    angular.module('serviceboxApp').controller('tourismCtrl', tourismCtrl);

    tourismCtrl.$inject = ['$scope', 'TourismRestServices', '$filter', '$sce', '$modal', '$aside'];
    function tourismCtrl($scope, TourismRestServices, $filter, $sce, $modal, $aside) {

        $scope.originalAttractions = [];
        $scope.attractionsListView = [];
        $scope.loading = false;
        $scope.categoriesLoading = false;
        $scope.showMapView = true;
        $scope.selectAllCategories = true;
        $scope.selectAllDistricts = true;
        $scope.initPhase = true;
        $scope.selectedDistrict = "Maseru";
        $scope.searchWord = "";
        $scope.showSearchField = false;
        $scope.showAllRoutes = false;
        $scope.showFilters = true;
        $scope.selectedAttraction = "";
        $scope.attractionViews = [];
        $scope.selectedAttractionView = "";
        $scope.selectedAttractionRoutes = [];
        $scope.viewsLoading = false;
        $scope.photoGalleries = [];
        $scope.photoGalleryLoading = false;
        $scope.attractionDetailsLoading = false;
        $scope.attractionRoutesLoading = false;
        var filters = {
            categories: [],
            districts: [],
            routes: []
        };

        $scope.lookups = {
            categories: [],
            districts: [
                {'name': 'Berea', 'selected': true},
                {'name': 'Botha Bothe', 'selected': true},
                {'name': 'Leribe', 'selected': true},
                {'name': 'Mafeteng', 'selected': true},
                {'name': 'Maseru', 'selected': true},
                {'name': "Mohale's Hoek", 'selected': true},
                {'name': 'Mokhotlong', 'selected': true},
                {'name': "Qacha's Nek", 'selected': true},
                {'name': 'Quthing', 'selected': true},
                {'name': 'Thaba Tseka', 'selected': true}
            ],
            selectAllDistricts: false,
            routes: [
                {
                    id: 1,
                    name: 'Highlands Ski Route',
                    selected: false,
                    legendImage: '../images/tourism/legend/skiRoute.png'
                },
                {
                    id: 2,
                    name: 'Lowlands Self Drive Route',
                    selected: false,
                    legendImage: '../images/tourism/legend/lowlandsRoute.png'
                },
                {
                    id: 3,
                    name: 'Highlands Circular Route',
                    selected: false,
                    legendImage: '../images/tourism/legend/higlandsCircularRoute.png'
                },

                {
                    id: 4,
                    name: 'Mountain Biking/4x4 Route',
                    selected: false,
                    legendImage: '../images/tourism/legend/mountainBiking.png'
                },
                {
                    id: 5,
                    name: 'Central Pony Trek/ Hiking Route',
                    selected: false,
                    legendImage: '../images/tourism/legend/centralPonyTrek.png'
                },
                {
                    id: 6,
                    name: 'Southern Rustic Route',
                    selected: false,
                    legendImage: '../images/tourism/legend/southernRoute.png'
                },
                {
                    id: 7,
                    name: 'King Moshoeshoe Historical Route',
                    selected: false,
                    legendImage: '../images/tourism/legend/moshoeshoeRoute.png'
                },
                {
                    id: 8,
                    name: 'Quthing Valley 4x4 Route',
                    selected: false,
                    legendImage: '../images/tourism/legend/quthingRoute.png'

                }
                //{id:9,name:'Extreme 4x4 Route',selected:true,style:'width: 40px;height: 20px;background-color:#fff'}
            ]
        };

        $scope.mapCenters = {
            all: {},
            berea: {},
            bothaBothe: {},
            leribe: {},
            mafeteng: {},
            maseru: {},
            mohalesHoek: {},
            mokhotlong: {},
            qachasNek: {},
            quthing: {},
            thabaTseka: {}
        };
        function setPrepareFilters() {
            var selectedDistricts = [];
            var selectedCategories = [];
            var selectedRoutes = [];
            angular.forEach($scope.lookups.categories, function (category, index) {

                if (category.selected) {
                    selectedCategories.push(category.id)
                }

            });
            angular.forEach($scope.lookups.districts, function (district, index) {

                if (district.selected) {
                    selectedDistricts.push(index + 1)
                }

            });
            angular.forEach($scope.lookups.routes, function (route, index) {

                if (route.selected) {
                    selectedRoutes.push(index + 1)
                }

            });

            filters.categories = selectedCategories;
            filters.districts = selectedDistricts;
            filters.routes = selectedRoutes;

        }

        angular.extend($scope, {
            center: {
                lat: -29.494597,
                lng: 28.332367,
                zoom: 8

            },

            markers: [],
            paths: {},
            decorations: '=',
            layers: {
                baselayers: {
                    googleHybrid: {
                        layerType: 'HYBRID',
                        name: 'Google Hybrid',

                        type: 'google'
                    },
                    googleRoadmap: {
                        name: 'Google Streets',
                        layerType: 'ROADMAP',
                        type: 'google'
                    },
                    googleTerrain: {
                        name: 'Google Terrain',
                        layerType: 'TERRAIN',
                        type: 'google'
                    }


                }
            }
        });

        $scope.init = function () {

            //Get Attractions Categories
            getAttractionsCategories();

            //EVENTS
            $scope.$on('switchView', function (event, args) {
                switchView();
            });
            $scope.$on('searchAttractionsClick', function () {
                $scope.showSearchField = !$scope.showSearchField;
                if ($scope.showSearchField) {
                    angular.element('#search').focus();
                }
            });
            $scope.$on('toggleRoutes', function () {

                $scope.showAllRoutes = !$scope.showAllRoutes;

            });
            $scope.$on('toggleRouteSelection', function () {
                showRouteSelectionAside();

            });
            $scope.$on('toggleCategorySelection', function () {
                showCategorySelectionAside();

            });
            $scope.$on('toggleFilters', function (event, data) {
                $scope.showFilters = data.showFilters;
            });
            $scope.$on('closeAttractionDetailsModal', function (event, data) {
                $scope.hideAttractionDetailsModal();
            });
            $scope.$on('activeRoute', function (event, route) {
                setPathsFromRoute(route.routeId);
                swal({
                    title: "Success!",
                    text: "I have activated the route for you. Only attractions along this route are shown.",
                    timer: 8000,
                    type: 'success'
                })

            });
            $scope.$watch('$viewContentLoaded', function () {

                $('select').select2({
                    allowClear: true,
                    // multiple:true,
                    // Specify format function for selected item
                    formatSelection: function (item) {
                        // return selection template
                        return '<div class="truncate">' + item.text + '</div>';
                    }

                });

            });

            $scope.$watch('selectAllDistricts', function (newValue, oldValue) {
                if (oldValue != newValue) {
                    if (newValue) {
                        angular.forEach($scope.lookups.districts, function (district, index) {
                            district.selected = true;
                        });
                        getAttractions();

                    }
                    else {
                        angular.forEach($scope.lookups.districts, function (district, index) {
                            district.selected = false;
                        });
                        $scope.markers = [];
                        $scope.attractionsListView = [];
                    }
                }


            });
            $scope.$watch('searchWord', function () {
                if ($scope.searchWord.length) {
                    var filteredAttractions = $filter('filter')($scope.originalAttractions, {description: $scope.searchWord});
                    if (filteredAttractions.length) {
                        $scope.markers = [];
                        $scope.markers = filteredAttractions;
                    }
                }
            });


        };
        function switchView() {
            $scope.showMapView = !$scope.showMapView;

        }

        $scope.updateDistrictSelection = function (selectedPosition) {
            getAttractions();
        };
        $scope.updateCategorySelection = function (selectedCategory) {
            getAttractions();
        };

        function getAttractions() {
            setPrepareFilters();
            //setCategoryFilter();
            $scope.originalAttractions = [];
            $scope.attractionsListView = [];
            $scope.markers = [];
            $scope.loading = true;
            TourismRestServices.getAttractions(filters, {
                success: function (attractionsFromServer) {
                    $scope.attractionsListView = attractionsFromServer.data;
                    angular.forEach(attractionsFromServer.data, function (item, index) {
                        $scope.markers.push({
                            lat: item.lat,
                            lng: item.lng,
                            icon: {
                                iconUrl: '/images/tourism/' + item.category.data.icon,
                                iconSize: [30, 47],
                                popupAnchor: [0, -48], // point from which the popup should open relative to the iconAnchor [15, 0]
                                iconAnchor: [15, 50], // point of the icon which will correspond to marker's location
                                labelAnchor: [7, -35]

                            },
                            message: "<div class=' style-primary-dark no-margin no-padding' style='width:315px' ng-controller='tourismCtrl'>" +
                            " <div class='row' style='margin-left:2px;margin-right: 2px'>" +
                            "<h4 class=' text-center text-uppercase text-bold col-md-12'>" + item.name + "</h4>" +
                            "<div class='col-md-12 card style-primary-dark' style='padding:0'><img width='100%' src='/images/tourism/places/" + item.image + "' /></div>" +
                            " <div class='col-xs-12 col-md-12 text-center'>" +
                            "<span class='text-ultra-bold pull-left'><i class='fa fa-phone fa fa-phone text-success'></i> " + item.phone + "</span>" +
                            "<a href='tel:" + item.phone + "' class='btn ink-reaction btn-raised btn-xs btn-success pull-right' style='color:#fff;margin-bottom:5px;'><i class='fa fa-phone'></i> CALL NOW</a>" +
                            "</div> " +
                            "<div class='col-md-12 text-bold text-justify  margin-bottom-lg '><span class='' style='margin-top: 5px;'>" + item.description.substring(0, 300) + "..." +
                            "<a href='#' class='text-accent-bright' ng-click='showAttractionDetailsModal(" + item.id + ")' style='color:#e9c9ef;'> Read more</a>" +
                            "</span></div> " +
                            "<div class='col-xs- 12 col-md-12'><div class='btn-group btn-group-justified' role='group' aria-label='Justified button group'>" +
                            "<a class='btn btn-default-bright btn-xs' role='button' ng-click='showAttractionDetailsModal(" + item.id + ")'><i class='fa fa-eye'></i> Details</a>" +
                            "<a class='btn btn-primary-bright btn-xs' ng-show='" + item.hasPhotoGallery + "' ng-click='showAttractionGalleryModal(" + item.id + ")'><i  class='fa fa-camera'>Gallery</i></a>" +
                            "<a ng-click='showAttraction360ViewModal(" + item.id + ")' ng-show='" + item.has360View + "' class='btn btn-primary btn-xs' ><img src='/images/3d.png'></a>" +
                            "</div>" +
                            "</div>" +
                            "</div>" +
                            "</div> ",
                            riseOnHover: true,
                            label: {"message": "<span class='text-ultra-bold text-lg' style='width:10px'>" + item.name + "</span>"},
                            name: item.name,
                            description: item.description,
                            image: item.image,
                            has360View: item.has360View

                        });

                    });
                    $scope.originalAttractions = $scope.markers;
                    $scope.loading = false;
                    //Remove html from names
                    angular.forEach($scope.attractionsListView, function (attraction, index) {
                        attraction.name = attraction.name.replace('<br/>', '').replace('<br/>', '');
                    });

                },
                error: function () {
                    $scope.loading = false;

                }
            });

        }

        function getAttractionsCategories() {
            $scope.categoriesLoading = true;
            TourismRestServices.getAttractionsCategories({
                success: function (attractionsCategoriesFromServer) {
                    $scope.lookups.categories = attractionsCategoriesFromServer.data;
                    $scope.categoriesLoading = false;
                    //GET LIST OF ATTRACTIONS
                    getAttractions();

                },
                error: function () {
                    $scope.categoriesLoading = false;
                }
            });

        }

        function getAttractionDetailsFromServer(attractionId) {
            $scope.attractionDetailsLoading = true;
            TourismRestServices.getAttractionDetails(attractionId, {
                success: function (attractionFromServer) {
                    $scope.selectedAttraction = attractionFromServer.data;
                    $scope.attractionDetailsLoading = false;
                    getAttractionRoutesFromServer(attractionId);

                },
                error: function () {
                    $scope.attractionDetailsLoading = false;
                }
            });

        }

        function getAttractionRoutesFromServer(attractionId) {
            $scope.attractionRoutesLoading = true;
            $scope.selectedAttractionRoutes = [];
            TourismRestServices.getAttractionRoutes(attractionId, {
                success: function (attractionRoutesFromServer) {
                    $scope.selectedAttractionRoutes = attractionRoutesFromServer.data;
                    $scope.attractionRoutesLoading = false;

                },
                error: function () {
                    $scope.attractionRoutesLoading = false;
                }
            });

        }

        $scope.getAttractionsViews = function (selectedAttraction) {
            $scope.viewsLoading = true;
            TourismRestServices.getAttractionViews(selectedAttraction, {
                success: function (attractionsViewsFromServer) {
                    $scope.attractionViews = attractionsViewsFromServer.data;
                    if ($scope.attractionViews.length > 0) {
                        $scope.selectedAttractionView = $sce.trustAsHtml($scope.attractionViews[0].view_code);
                    }
                    else {
                        $scope.selectedAttractionView = "";
                    }
                    $scope.viewsLoading = false;
                },
                error: function () {
                    $scope.viewsLoading = false;
                }
            });

        };
        $scope.getAttractionPhotoGalley = function (selectedAttraction) {
            $scope.selectedAttraction = selectedAttraction;
            $scope.photoGalleryLoading = true; //dispatch massage
            TourismRestServices.getAttractionPhotoGallery($scope.selectedAttraction, {
                success: function (attractionsPhotoGalleriesFromServer) {
                    var attractionPhotoGalleries = attractionsPhotoGalleriesFromServer.data;
                    $scope.photoGalleries = [];
                    if (attractionPhotoGalleries.length > 0) {
                        angular.forEach(attractionPhotoGalleries, function (gallery, index) { //Build html
                            $scope.photoGalleries.push($sce.trustAsHtml(" <div id='gallery-" + index + "' class='fb-album-container col-md-12'></div>"));

                        });
                        setTimeout(function () {
                                angular.forEach(attractionPhotoGalleries, function (gallery, index) {
                                    $("#gallery-" + index).html('').FacebookAlbumBrowser({
                                        account: gallery.fbAccountName,
                                        accessToken: "1618596101799847|5pbrRihe5ZLUOaGVT8Lb20tXanA",
                                        //includeAlbums: [gallery.albumName],
                                        onlyAlbum: gallery.albumName,
                                        thumbnailSize: 100,
                                        showComments: true,
                                        commentsLimit: 5,
                                        showAccountInfo: true,
                                        showImageCount: true,
                                        showImageText: true,
                                        albumsPageSize: 10,
                                        photosPageSize: 10,
                                        lightbox: true,
                                        photosCheckbox: false,
                                        pluginImagesPath: "/images/",
                                        likeButton: true,
                                        shareButton: true,
                                        addThis: "ra-52638e915dd79612"
                                    });

                                });
                                $scope.photoGalleryLoading = false;
                                $scope.$apply();
                            }
                            , 3000);
                    }


                },
                error: function () {
                    $scope.photoGalleryLoading = false;
                }
            });

        };

        $scope.switchViewPoint = function (viewPointIndex) {
            $scope.viewsLoading = true;
            $scope.selectedAttractionView = $sce.trustAsHtml($scope.attractionViews[viewPointIndex].view_code);
            setTimeout(function () {
                $scope.viewsLoading = false;
                $scope.$apply();
            }, 2000)
        };

        $scope.setPaths = function () {
            $scope.paths = {};
            angular.forEach($scope.lookups.routes, function (route, index) {
                switch (route.id) {
                    case 3://Highlands Circular Route
                        if (route.selected) {
                            TourismRestServices.getHighlandsCircularRoute({

                                success: function (data) {
                                    $scope.paths['HighlandsCircularRoute'] = data;
                                }
                            });
                        }
                        break;
                    case 8://Quthing Valley 4x4 Route
                        if (route.selected) {
                            TourismRestServices.getQuthingValleyP1Route({

                                success: function (data) {
                                    $scope.paths['QuthingValleyP1Route'] = data;
                                }
                            });
                            TourismRestServices.getQuthingValleyP2Route({

                                success: function (data) {
                                    $scope.paths['QuthingValleyP2Route'] = data;
                                }
                            });
                            TourismRestServices.getQuthingValleyP3Route({

                                success: function (data) {
                                    $scope.paths['QuthingValleyP3Route'] = data;
                                }
                            });
                        }
                        break;
                    case 2://lowandsSelfDrive
                        if (route.selected) {
                            TourismRestServices.getLowlandsRoute({
                                success: function (data) {
                                    $scope.paths['lowandsSelfDrive'] = data;
                                }
                            });
                        }
                        break;
                    case 1:
                        if (route.selected) {
                            TourismRestServices.getHighlandsSkiRoute({
                                success: function (data) {
                                    $scope.paths['highlandsSkiRoute'] = data;
                                }
                            });
                        }
                        break;
                    case 4://mountain4x4Route
                        if (route.selected) {
                            TourismRestServices.getMountain4x4Route({
                                success: function (data) {
                                    $scope.paths['mountain4x4Route'] = data;
                                }
                            });
                            TourismRestServices.getMountain4x4RouteP1({

                                success: function (data) {
                                    $scope.paths['Mountain4x4RouteP1'] = data;
                                }
                            });
                            TourismRestServices.getMountain4x4RouteP2({

                                success: function (data) {
                                    $scope.paths['Mountain4x4RouteP2'] = data;
                                }
                            });
                        }
                        break;
                    case 5://CentralPonyTrekRoute
                        if (route.selected) {
                            TourismRestServices.getKingCentralPonyTrekRoute({
                                success: function (data) {
                                    $scope.paths['CentralPonyTrekRoute'] = data;
                                }
                            });
                            TourismRestServices.getKingCentralPonyTrekP1Route({
                                success: function (data) {
                                    $scope.paths['CentralPonyTrekRouteP1'] = data;
                                }
                            });
                        }
                        break;
                    case 6://SouthernRusticRoute
                        if (route.selected) {
                            TourismRestServices.getSouthernRusticRoute({

                                success: function (data) {
                                    $scope.paths['SouthernRusticRoute'] = data;
                                }
                            });
                        }
                        break;
                    case 7://KingMoshoeshoeHistoricalRoute
                        if (route.selected) {
                            TourismRestServices.getKingMoshoeshoeHistoricalRoute({

                                success: function (data) {
                                    $scope.paths['KingMoshoeshoeHistoricalRoute'] = data;
                                }
                            });
                        }
                        break;
                    default:
                }

            });
            getAttractions();


        };
        var setPathsFromRoute = function (routeId) { //Called in Attraction details modal when route is selected
            $scope.showAllRoutes = false; //Hide all routes
            //togglePaths();
            angular.forEach($scope.lookups.routes, function (route, index) {
                route.selected = route.id == routeId;
            });
            $scope.setPaths();
            // getAttractions();//Refresh Attractions based on the selected route
            $scope.hideAttractionDetailsModal(); //Close details modal if displayed

        };

        $scope.togglePaths = function () {
            $scope.showAllRoutes = !$scope.showAllRoutes;
            if ($scope.showAllRoutes) {
                angular.forEach($scope.lookups.routes, function (route, index) {
                    route.selected = true;
                });
                $scope.setPaths();
            }
            else {
                angular.forEach($scope.lookups.routes, function (route, index) {
                    route.selected = false;
                });
                $scope.setPaths();
            }
        };

        $scope.toggleCategories = function () {
            $scope.selectAllCategories = !$scope.selectAllCategories;
            if ($scope.selectAllCategories) {
                angular.forEach($scope.lookups.categories, function (category, index) {
                    category.selected = true;
                });
                getAttractions();

            }
            else {
                angular.forEach($scope.lookups.categories, function (category, index) {
                    category.selected = false;
                });
                $scope.markers = [];
                $scope.attractionsListView = [];
            }
        };


        //MODAL WINDOWS
        var attraction360ViewModal = $modal({
            scope: $scope,
            //  backdrop: 'static',
            animation: 'am-fade-and-scale',
            templateUrl: '../app/tourism/templates/attraction360ViewModal.tpl.html',
            show: false
        });

        $scope.showAttraction360ViewModal = function (attractionId) {
            $scope.getAttractionsViews(attractionId);
            attraction360ViewModal.$promise.then(attraction360ViewModal.show);
        };
        var attractionGalleryModal = $modal({
            scope: $scope,
            // backdrop: 'static',
            animation: 'am-fade-and-scale',
            templateUrl: '../app/tourism/templates/attractionGalleryModal.tpl.html',
            show: false
        });
        $scope.showAttractionGalleryModal = function (attractionId) {
            $scope.getAttractionPhotoGalley(attractionId);
            attractionGalleryModal.$promise.then(attractionGalleryModal.show);
        };
        var attractionDetailsModal = $modal({
            scope: $scope,
            // backdrop: 'static',
            animation: 'am-fade-and-scale',
            templateUrl: '../app/tourism/templates/attractionDetailsModal.tpl.html',
            show: false
        });
        $scope.showAttractionDetailsModal = function (attractionId) {
            getAttractionDetailsFromServer(attractionId);
            attractionGalleryModal.$promise.then(attractionDetailsModal.show);
        };
        $scope.hideAttractionDetailsModal = function () {
            attractionGalleryModal.$promise.then(attractionDetailsModal.hide);
        };

        //ASIDES
        var routeSelectionAside = $aside({
            scope: $scope,
            backdrop: false,
            templateUrl: '../app/tourism/templates/routesSelectionAside.tpl.html',
            placement: 'right',
            container: 'body',
            animation: 'am-animation-slide-left',
            show: false
        });
        var showRouteSelectionAside = function () {
            routeSelectionAside.$promise.then(routeSelectionAside.show);
        };

        var categorySelectionAside = $aside({
            scope: $scope,
            backdrop: false,
            templateUrl: '../app/tourism/templates/categorySelectionAside.tpl.html',
            placement: 'right',
            container: 'body',
            animation: 'am-animation-slide-left',
            show: false
        });
        var showCategorySelectionAside = function () {
            categorySelectionAside.$promise.then(categorySelectionAside.show);
        };


    }


})
();

(function () {
    'use strict';

    angular
        .module('serviceboxApp')
        .service('contactsService', contactsService);

    contactsService.$inject = ['$http', '$q'];
    function contactsService($http, $q) {
        var baseUri = "/api/contacts/",
            contactsService = {};

        contactsService.getEmergencyContacts = function (callbacks) {
            var df = $q.defer();
            $http.get(baseUri + 'emergency', {cache: true}).success(function (responseData) {
                if (callbacks && callbacks.success) {
                    callbacks.success(responseData);
                }
                df.resolve(responseData);
            }).error(function () {
                if (callbacks && callbacks.error) {
                    callbacks.error("An error occurred while fetching emergency contacts");
                }
                df.reject();
            });
        };
        contactsService.getPoliceStations = function (filters, callbacks) {
            var query = preparePoliceStationFilters(filters);

            var df = $q.defer();
            $http.get(baseUri + query, {cache: true}).success(function (responseData) {
                if (callbacks && callbacks.success) {
                    callbacks.success(responseData);
                }
                df.resolve(responseData);
            }).error(function () {
                if (callbacks && callbacks.error) {
                    callbacks.error("An error occurred while fetching Police Stations");
                }
                df.reject();
            });
        };
        contactsService.getTelephoneTaxis = function (filters, callbacks) {
            var query = prepareTelephoneTaxisFilters(filters);

            var df = $q.defer();
            $http.get(baseUri + query, {cache: true}).success(function (responseData) {
                if (callbacks && callbacks.success) {
                    callbacks.success(responseData);
                }
                df.resolve(responseData);
            }).error(function () {
                if (callbacks && callbacks.error) {
                    callbacks.error("An error occurred while fetching Telephones Taxis");
                }
                df.reject();
            });
        };
        function preparePoliceStationFilters(filters) {
            var query = 'police-stations';
            if (filters && filters.types) {
                if (query.length > 15) {
                    query += '&type=' + filters.types;
                }
                else {
                    query += '?type=' + filters.types;
                }


            }
            if (filters && filters.districts) {
                if (query.length > 15) {
                    query += '&district=' + filters.districts;
                }
                else {
                    query += '?district=' + filters.districts;
                }



            }
            if (filters && filters.userLatLng) {
                if (query.length > 15) {
                    query += '&latlng=' + filters.userLatLng.join();
                }
                else {
                    query += '?latlng=' + filters.userLatLng.join();
                }



            }

            return query;
        }

        function prepareTelephoneTaxisFilters(filters) {
            var query = 'telephone-taxis';

            if (filters && filters.districts) {
                if (query.length > 15) {
                    query += '&district=' + filters.districts;
                }
                else {
                    query += '?district=' + filters.districts;
                }


            }

            return query;
        }

        return contactsService;
    }
})();
(function () {
    'use strict';

    angular
        .module('serviceboxApp')
        .service('accommodationService', accommodationService);

    accommodationService.$inject = ['$http', '$q'];
    function accommodationService($http, $q) {
        var baseUri ="/api/";
        var accommodationService = {};

        function prepareFilters(filters) {
            var query = 'accommodation';
            if (filters && filters.types && filters.types.length > 0) {
                if (query.length > 13) {
                    query += '&type=' + filters.types.join();
                }
                else {
                    query += '?type=' + filters.types.join();
                }


            }
            if (filters && filters.districts) {
                if (query.length > 13) {
                    query += '&district=' + filters.districts;
                }
                else {
                    query += '?district=' + filters.districts;
                }


            }

            return query;
        }

        accommodationService.getAccommodation = function (filters, callbacks) {
            var query = prepareFilters(filters);

            var df = $q.defer();
            $http.get(baseUri + query).success(function (responseData) {
                if (callbacks && callbacks.success) {
                    callbacks.success(responseData);
                }
                df.resolve(responseData);
            }).error(function () {
                if (callbacks && callbacks.error) {
                    callbacks.error("An error occurred while fetching accommodation");
                }
                df.reject();
            });
        };

        return accommodationService;
    }
})();
(function () {
    'use strict';

    angular
        .module('serviceboxApp')
        .controller('accommodationCtrl', accommodationCtrl);

    accommodationCtrl.$inject = ['$scope', '$window', 'accommodationService'];
    function accommodationCtrl($scope, $window, accommodationService) {
        $scope.showFilters=true;
        $scope.lookups = {
            types: [
                {id: 1, name: 'Hotels', selected: true, color: '#00E03D'},
                {id: 2, name: 'Lodges', selected: true, color: '#55D8D8'},
                {id: 3, name: 'B&Bs', selected: true, color: '#FBF358'},
                {id: 4, name: 'Guest Houses', selected: true, color: '#FC6355'},
                {id: 5, name: 'Resorts', selected: true, color: '#DF509F'}
            ],
            districts: [
                {'name': 'Berea', 'selected': true},
                {'name': 'Botha Bothe', 'selected': true},
                {'name': 'Leribe', 'selected': true},
                {'name': 'Mafeteng', 'selected': true},
                {'name': 'Maseru', 'selected': true},
                {'name': "Mohale's Hoek", 'selected': true},
                {'name': 'Mokhotlong', 'selected': true},
                {'name': "Qacha's Nek", 'selected': true},
                {'name': 'Quthing', 'selected': true},
                {'name': 'Thaba Tseka', 'selected': true}
            ],
            selectAllDistricts: false
        };
        $scope.filters = {
            types: [],
            districts: []
        };
        $scope.selectAllTypes = true;

        $scope.init = function () {
            $scope.$on('toggleFilters', function (event, data) {
                $scope.showFilters = !$scope.showFilters;
            });
            getAccommodation();

            $scope.$watch('selectAllTypes', function (newValue, oldValue) {
                if (oldValue != newValue) {
                    if (newValue) {
                        angular.forEach($scope.lookups.types, function (type, index) {
                            type.selected = true;
                        });
                        getAccommodation();

                    }
                    else {
                        angular.forEach($scope.lookups.types, function (type, index) {
                            type.selected = false;
                        });
                        $scope.markers = [];
                        $scope.attractionsListView = [];
                    }
                }


            });
        };
        angular.extend($scope, {
            center: {
                lat: -29.494597,
                lng: 28.332367,
                zoom: 9

            },

            markers: [],
            paths: {},
            layers: {
                baselayers: {
                    googleRoadmap: {
                        name: 'Google Streets',
                        layerType: 'ROADMAP',
                        type: 'google'
                    },
                    googleHybrid: {
                        name: 'Google Hybrid',
                        layerType: 'HYBRID',
                        type: 'google'
                    },

                    googleTerrain: {
                        name: 'Google Terrain',
                        layerType: 'TERRAIN',
                        type: 'google'
                    }


                }
            }
        });
        $scope.updateAccommodationWithSelectedType = function () {
            getAccommodation();
        };
        function getAccommodation() {
            setPrepareFilters();
            $scope.originalAttractions = [];
            $scope.attractionsListView = [];
            $scope.markers = [];
            $scope.loading = true;
            accommodationService.getAccommodation($scope.filters, {
                success: function (accommodationFromServer) {
                    $scope.accommodationListView = accommodationFromServer.data;
                    angular.forEach(accommodationFromServer.data, function (item, index) {


                        var phone = " <div class='row text-center' ng-show='" + item.hasPhone + "'>" +
                            "<span class='col-xs-10 text-lg text-shadow text-left'><i class='fa fa-phone  fa-phone fa-2x  text-success'></i> " + item.phone + "</span>" +
                            "</div> <hr class='ruler-lg' ng-show='" + item.hasPhone + "'/>";
                        var cell = " <div class='row text-center' ng-show='" + item.hasCell + "'>" +
                            "<span class='text-lg text-shadow pull-left'><i class='fa fa-mobile-phone margin-right-10 font-size-40 fa-2x  text-success' style='margin-left: 5px'></i> " + item.cell + "</span>" +
                            "</div> <hr class='ruler-lg' ng-show='" + item.hasCell + "'/>";
                        var email = " <div class='row text-center' ng-show='" + item.hasEmail + "'>" +
                            "<span class='text-lg text-shadow pull-left'><i class='fa  fa-envelope-o  fa-2x text-success text-xl'></i> " + item.email + "</span>" +
                            "</div> <hr class='ruler-lg' ng-show='" + item.hasEmail + "'/>";


                        $scope.markers.push({
                            lat: item.latitude,
                            lng: item.longitude,
                            //group: 'accommodation',
                            icon: {
                                iconUrl: '/images/accommodation/markers/' + item.icon,
                                iconSize: [20, 30],
                                popupAnchor: [0, -40], // point from which the popup should open relative to the iconAnchor [15, 0]
                                iconAnchor: [10, 40], // point of the icon which will correspond to marker's location
                                labelAnchor: [7, -30]

                            },
                            riseOnHover: true,
                            message: "<div class=' style-primary-dark no-margin no-padding' style='width:315px' ng-controller='accommodationCtrl'>" +
                            " <div class='row' style='margin-left:2px;margin-right: 2px'>" +
                            "<h4 class=' text-center text-uppercase text-bold col-md-12'>" + item.name + "</h4>"
                            + phone
                            + cell
                            + email +
                            "<div class='col-xs- 12 col-md-12'><div class='btn-group btn-group-justified' role='group' aria-label='Justified button group'>" +
                            "<a ng-show='" + item.hasWebsite + "' ng-href='http://" + item.website + "' target='_blank' class='btn btn-default-bright btn-xs'><i class='fa fa-globe'></i> Website</a>" +
                            "<a class='btn btn-primary-bright btn-xs' ng-show='false' ng-click='showAttractionGalleryModal(" + item.id + ")'><i  class='fa fa-camera'>Gallery</i></a>" +
                            "</div>" +
                            "</div>" +
                            "</div>" +
                            "</div> ",
                            label: {"message": "<span class='text-ultra-bold text-lg' style='width:10px'>" + item.name + "</span>"},
                            /* name: item.name,
                             description: item.description,
                             image: item.image,
                             has360View: item.has360View*/

                        });

                    });
                    $scope.originalAccommodationList = $scope.markers;
                    $scope.loading = false;


                },
                error: function () {
                    $scope.loading = false;

                }
            });

        }

        function setPrepareFilters() {
            var selectedDistricts = [];
            var selectedTypes = [];
            angular.forEach($scope.lookups.types, function (type, index) {

                if (type.selected) {
                    selectedTypes.push(type.id)
                }

            });
            angular.forEach($scope.lookups.districts, function (district, index) {

                if (district.selected) {
                    selectedDistricts.push(index + 1)
                }

            });


            $scope.filters.types = selectedTypes;
            // filters.districts = selectedDistricts;

        }

        $scope.goToWebsite = function (itemId) {
            console.info($scope.accommodationListView);
            angular.forEach($scope.accommodationListView, function (facility, index) {
                console.log(facility.id);
                if (facility.id == itemId) {

                    $window.location.href = "http://" + facility.website;
                }
            });
            // $window.location.href = "tel:2345";
        };
    }
})();
(function() {
'use strict';

    angular
        .module('serviceboxApp')
        .service('bankService', bankService);

    bankService.$inject = ['$http','$q'];
    function bankService($http,$q) {
       var baseUri ="/api/banks/";
        var bankService = {};

        function prepareATMsFilters(filters) {
            var query = 'atms';
            if (filters && filters.bank) {
                if (query.length > 4) {
                    query += '&bank=' + filters.bank;
                }
                else {
                    query += '?bank=' + filters.bank;
                }


            }
            if (filters && filters.districts) {
                if (query.length > 4) {
                    query += '&district=' + filters.districts;
                }
                else {
                    query += '?district=' + filters.districts;
                }


            }

            return query;
        }
        function prepareBranchesFilters(filters) {
            var query = 'branches';
            if (filters && filters.bank) {
                if (query.length > 8) {
                    query += '&bank=' + filters.bank;
                }
                else {
                    query += '?bank=' + filters.bank;
                }


            }
            if (filters && filters.districts) {
                if (query.length > 8) {
                    query += '&district=' + filters.districts;
                }
                else {
                    query += '?district=' + filters.districts;
                }


            }

            return query;
        }

        bankService.getATMs= function (filters, callbacks) {
            var query = prepareATMsFilters(filters);

            var df = $q.defer();
            $http.get(baseUri + query,{cache: true}).success(function (responseData) {
                if (callbacks && callbacks.success) {
                    callbacks.success(responseData);
                }
                df.resolve(responseData);
            }).error(function () {
                if (callbacks && callbacks.error) {
                    callbacks.error("An error occurred while fetching ATMS");
                }
                df.reject();
            });
        };
        bankService.getBranches = function (filters, callbacks) {
            var query = prepareBranchesFilters(filters);

            var df = $q.defer();
            $http.get(baseUri + query,{cache: true}).success(function (responseData) {
                if (callbacks && callbacks.success) {
                    callbacks.success(responseData);
                }
                df.resolve(responseData);
            }).error(function () {
                if (callbacks && callbacks.error) {
                    callbacks.error("An error occurred while fetching Branches");
                }
                df.reject();
            });
        };

        return bankService;
        }
})();
(function () {
    'use strict';

    angular
        .module('serviceboxApp')
        .controller('bankCtrl', bankController);

    bankController.$inject = ['$scope', '$location', 'bankService'];
    function bankController($scope, $location, bankService) {
        $scope.mapView = true;
        $scope.atmCardView = false;
        $scope.branchCardView = false;
        $scope.showFilters = true;
        $scope.showATMS = true;
        $scope.loading = false;
        $scope.atmCount = 0;
        $scope.branchCount = 0;
        $scope.showSearchField = false;
        $scope.searchWord = '';
        $scope.initMode = true;
        $scope.atmList = [];
        $scope.branchList = [];
        $scope.lookups = {
            districts: [
                {'name': 'Berea', 'selected': true},
                {'name': 'Botha Bothe', 'selected': true},
                {'name': 'Leribe', 'selected': true},
                {'name': 'Mafeteng', 'selected': true},
                {'name': 'Maseru', 'selected': true},
                {'name': "Mohale's Hoek", 'selected': true},
                {'name': 'Mokhotlong', 'selected': true},
                {'name': "Qacha's Nek", 'selected': true},
                {'name': 'Quthing', 'selected': true},
                {'name': 'Thaba Tseka', 'selected': true}
            ],
            selectAllDistricts: true
        };
        $scope.filters = {
            bank: '',
            districts: []
        };
        $scope.init = function () {
            $scope.$on('switchToAtmView', function (event, data) {
                if (!$scope.initMode) {
                    $scope.mapView = false;
                    $scope.atmCardView = true;
                    $scope.branchCardView = false;
                }

            });
            $scope.$on('switchToBranchView', function (event, data) {
                if (!$scope.initMode) {
                    $scope.mapView = false;
                    $scope.atmCardView = false;
                    $scope.branchCardView = true;
                }

            });
            $scope.$on('switchToMapView', function (event, data) {
                if (!$scope.initMode) {
                    $scope.mapView = true;
                    $scope.atmCardView = false;
                    $scope.branchCardView = false;
                }

            });
            $scope.$on('toggleFilters', function (event, data) {
                $scope.showFilters = !$scope.showFilters;
            });
            $scope.$on('toggleATMs', function (event, data) {
                $scope.showATMS = !$scope.showATMS;
                getATMsAndBranchesFromServer();
            });
            $scope.$watch('lookups.selectAllDistricts', function (newValue, oldValue) {
                if (oldValue != newValue) {
                    if (newValue) {
                        angular.forEach($scope.lookups.districts, function (district, index) {
                            district.selected = true;
                        });
                        getATMsAndBranchesFromServer();

                    }
                    else {
                        angular.forEach($scope.lookups.districts, function (district, index) {
                            district.selected = false;
                        });
                        $scope.atmList = [];
                        $scope.branchList = [];
                        $scope.atmCount = 0;
                        $scope.branchCount = 0;
                        $scope.markers = [];
                    }
                }


            });
            $scope.$watch('searchWord', function () {
                if ($scope.searchWord.length) {
                    var filteredAttractions = $filter('filter')($scope.originalAttractions, {description: $scope.searchWord});
                    if (filteredAttractions.length) {
                        $scope.markers = [];
                        $scope.markers = filteredAttractions;
                    }
                }
            });
            getATMsAndBranchesFromServer();
            $scope.initMode = false;

        };
        angular.extend($scope, {
            center: {
                lat: -29.494597,
                lng: 28.332367,
                zoom: 8

            },

            markers: [],
            paths: {},
            layers: {
                baselayers: {
                    googleRoadmap: {
                        name: 'Google Streets',
                        layerType: 'ROADMAP',
                        type: 'google'
                    },
                    googleHybrid: {
                        name: 'Google Hybrid',
                        layerType: 'HYBRID',
                        type: 'google'
                    }


                }
            }
        });
        function getATMsAndBranchesFromServer() {

            setPrepareFilters();
            $scope.markers = [];
            $scope.atmList = [];
            $scope.branchList = [];
            $scope.atmCount = 0;
            $scope.branchCount = 0;
            $scope.loading = true;

            bankService.getATMs($scope.filters, {
                success: function (atmsFromServer) {
                    if ($scope.showATMS) {
                        $scope.atmList = atmsFromServer.data;
                        angular.forEach(atmsFromServer.data, function (item, index) {
                            $scope.atmCount++;
                            $scope.markers.push({
                                lat: item.latitude,
                                lng: item.longitude,
                                //group: 'accommodation',
                                icon: {
                                    iconUrl: '/images/banks/std-atm.png',
                                    iconSize: [20, 30],
                                    popupAnchor: [0, -40], // point from which the popup should open relative to the iconAnchor [15, 0]
                                    iconAnchor: [10, 40], // point of the icon which will correspond to marker's location
                                    labelAnchor: [7, -30]

                                },
                                riseOnHover: true,
                                message: "<div class='style-primary-dark no-margin no-padding text-' style='width:315px' ng-controller='bankCtrl'>" +
                                "<span class='stick-top-left' style='margin-right: 30px;margin-top:7px'> <span class='label label-danger'><i class='fa fa-phone'></i> EMERGENCY</span> <span class='text-ultra-bold text-primary-bright text-shadow'>" + item.phone + "</span> </span>" +
                                "<div class='holder'>" +
                                "<div class='overlay text-center'><span class='text-primary-bright text-lg text-bold text-uppercase'>" + item.location + "</span>" +
                                "<br/><span class=' label label-info' style=''>ATM</span> " +
                                "<div class='text-divider text-primary-bright margin-bottom-10'><span><i class='fa fa-map-marker'></i></span></div>" +
                                "<div class='col-xs-12'><span>GPS <span class='text-sm text-muted'>(Latitude/Longitude)</span><br></span> <span class='text-ultra-bold text-primary-bright'>" + item.latitude + ", " + item.longitude + "</span>" +
                                "</div>" +
                                "</div>" +
                                "</div>" +
                                "<img class='img-responsive' src='./images/atm/standardbank.png'style='display: block;margin:auto;margin-top: 30px;' alt=''>" +
                                "<div class='stick-bottom-left' style='margin-bottom:25px;'>" +
                                "<span class=' label label-info text-uppercase' style=''>" + item.district.data.name + "</span> " +
                                "</div>" +
                                "<span ng-show='false' class='stick-bottom-right label label-info'  style='margin-bottom:25px;'>" +
                                "<a style='color:#ffffff' href='./directions?end=-29.338678,27.461934'>Get Directions</a></span>" +
                                "</div>",
                                label: {
                                    "message": "<div class='text-center'>" +
                                    " <span class='text-ultra-bold text-lg' style='width:10px'>" + item.location + "</span><br/>" +
                                    " <span>ATM</span>" +
                                    "</div>"
                                }


                            });

                        });
                    }
                    $scope.loading = false;


                },
                error: function () {
                    $scope.loading = false;

                }
            });

            bankService.getBranches($scope.filters, {
                success: function (branchesFromServer) {
                    $scope.branchList = branchesFromServer.data;
                    angular.forEach(branchesFromServer.data, function (item, index) {
                        $scope.branchCount++;
                        $scope.markers.push({
                            lat: item.latitude,
                            lng: item.longitude,
                            //group: 'accommodation',
                            icon: {
                                iconUrl: '/images/banks/std-branch.png',
                                iconSize: [20, 30],
                                popupAnchor: [0, -40], // point from which the popup should open relative to the iconAnchor [15, 0]
                                iconAnchor: [10, 40], // point of the icon which will correspond to marker's location
                                labelAnchor: [7, -30]

                            },
                            riseOnHover: true,
                            message: "<div class='style-primary-dark no-margin no-padding height-8' style='width:315px' ng-controller='bankCtrl'>" +
                            "<div class='holder'>" +
                            "<div class='overlay text-center'>" +
                            "<span class=' text-xl text-shadow  text-uppercase col-xs-12'>" + item.name + "</span> <br/>" +
                            "<span class=' label label-success' style=''>BRANCH</span> " +
                            "<span class='text-primary-bright text-uppercase col-xs-12'>" + item.location + "</span>" +
                            "<div class='text-divider text-primary-bright margin-bottom-10'><span>Branch Details</span></div>" +

                            "<div class='col-xs-12' ng-show='" + item.hasCode + "'><span class='pull-left text-ultra-bold text-shadow  text-primary-bright'>Code</span> <span class='text-primary-bright text-lg pull-right'>" + item.code + "</span>" +
                            "</div>" +
                            "<div class='col-xs-12'><span class='pull-left text-ultra-bold text-shadow  text-primary-bright'><i class='fa fa-phone'></i></span> <span class='text-primary-bright text-lg pull-right'>" + item.phone + "</span>" +
                            "</div>" +
                            "<div class='col-xs-12'><span class='pull-left text-ultra-bold text-shadow  text-primary-bright'>GPS</span> <span class='text-primary-bright text-lg pull-right'>" + item.latitude + ", " + item.longitude + "</span>" +
                            "</div>" +
                            "<div class='col-xs-12'><span class='pull-left text-ultra-bold text-shadow  text-primary-bright'><i class='fa fa-globe'></i></span> <a target='_blank' ng-href='" + item.website + "' class='label style-primary text-sm pull-right' style='color:#caf0ee;'>Visit Website</a>" +
                            "</div>" +

                            "<div class='text-divider text-primary-bright margin-bottom-10'><span>Hours Of Operation</span></div>" +
                            "<div class='col-xs-12'><span class='pull-left text-ultra-bold text-shadow  text-primary-bright'>Mon-Fri</span> <span class='text-primary-bright text-lg pull-right'>" + item.week_op_hrs + "</span>" +
                            "</div>" +
                            "<div class='col-xs-12'><span class='pull-left text-ultra-bold text-shadow  text-primary-bright'>Sat</span> <span class='text-primary-bright text-lg pull-right'>" + item.sat_op_hrs + "</span>" +
                            "</div>" +
                            "<div class='col-xs-12'><span class='pull-left text-ultra-bold text-shadow  text-primary-bright'>Sun</span> <span class='text-primary-bright text-lg pull-right'>" + item.sun_op_hrs + "</span>" +
                            "</div>" +

                            "</div>" +
                            "</div>" +
                            "<img class='img-responsive' src='./images/atm/standardbank.png'style='display: block;margin:auto;margin-top: 30px;' alt=''>" +
                            "<div class='stick-bottom-left' style='margin-bottom:25px;'>" +
                            "<span class=' label label-success text-uppercase' style=''>" + item.district.data.name + "</span> " +
                            "</div>" +
                            "<span ng-show='false' class='stick-bottom-right label label-success text-uppercase' style='margin-bottom:25px;'>" +
                            "<a style='color:#ffffff' href='./directions?end=-29.338678,27.461934'>Get Directions</a></span>" +
                            "</div>",
                            label: {
                                "message": "<div class='text-center'>" +
                                " <span class='text-ultra-bold text-lg' style='width:10px'>" + item.location + "</span><br/>" +
                                " <span>BRANCH</span>" +
                                "</div>"
                            },


                        });

                    });
                    $scope.originalAccommodationList = $scope.markers;
                    $scope.loading = false;


                },
                error: function () {
                    $scope.loading = false;

                }
            });
        }

        $scope.updateDistrictSelection = function () {
            getATMsAndBranchesFromServer();
        };
        function setPrepareFilters() {
            var selectedDistricts = [];
            var bank = '';
            if ($location.absUrl().indexOf('standardlesothobank') > -1) {
                bank = 1;
            }
            else if ($location.absUrl().indexOf('fnb') > -1) {
                bank = 2;
            }
            else if ($location.absUrl().indexOf('nedbank') > -1) {
                bank = 3;
            }
            else {
                bank = '';
            }

            angular.forEach($scope.lookups.districts, function (district, index) {

                if (district.selected) {
                    selectedDistricts.push(index + 1)
                }

            });


            $scope.filters.bank = bank;
            $scope.filters.districts = selectedDistricts;

        }
    }
})();
(function () {
    'use strict';
    angular
        .module('serviceboxApp')
        .service('MobileMoneyService', MobileMoneyService);

    MobileMoneyService.$inject = ['$http', '$q'];
    function MobileMoneyService($http, $q) {
        var baseUri = "/api/mobile-money/";
        var agentService = {};


        function prepareFilters(filters) {
            var query = 'agents';
            if (filters && filters.provider) {
                if (query.length > 6) {
                    query += '&provider=' + filters.provider;
                }
                else {
                    query += '?provider=' + filters.provider;
                }


            }
            if (filters && filters.districts) {
                if (query.length > 6) {
                    query += '&district=' + filters.districts;
                }
                else {
                    query += '?district=' + filters.districts;
                }


            }
            if (filters && filters.searchWord) {
                if (query.length > 6) {
                    query += '&search=' + filters.searchWord;
                }
                else {
                    query += '?search=' + filters.searchWord;
                }
            }

            return query;
        }

        agentService.getAgents = function (filters, callbacks) {
            var query = prepareFilters(filters);
            var df = $q.defer();
            $http.get(baseUri + query, {cache: true}).success(function (responseData) {
                if (callbacks && callbacks.success) {
                    callbacks.success(responseData);
                }
                df.resolve(responseData);
            }).error(function () {
                if (callbacks && callbacks.error) {
                    callbacks.error("An error occurred while fetching Mobile Money Agents");
                }
                df.reject();
            });
        };

        return agentService;
    }

})();

(function () {
    'use strict';

    angular.module('serviceboxApp')
        .controller('mobileMoneyCtrl', mobileMoneyCtrl);

    mobileMoneyCtrl.$inject = ['$scope', '$location', '$anchorScroll', 'MobileMoneyService'];
    function mobileMoneyCtrl($scope, $location, $anchorScroll, mobileMoneyService) {
        $scope.loading = false;
        $scope.showFilters = true;
        $scope.showSearchField = false;
        $scope.mapView = false;
        $scope.searchWord = '';
        $scope.groupAgents = true;
        $scope.agentList = [];
        $scope.initPhase = true;
        $scope.lookups = {
            districts: [
                {'name': 'Berea', 'selected': false},
                {'name': 'Botha Bothe', 'selected': false},
                {'name': 'Leribe', 'selected': false},
                {'name': 'Mafeteng', 'selected': false},
                {'name': 'Maseru', 'selected': false},
                {'name': "Mohale's Hoek", 'selected': false},
                {'name': 'Mokhotlong', 'selected': false},
                {'name': "Qacha's Nek", 'selected': false},
                {'name': 'Quthing', 'selected': false},
                {'name': 'Thaba Tseka', 'selected': false}
            ],
            selectAllDistricts: true
        };
        $scope.filters = {
            provider: '',
            districts: [],
            searchWord: ''
        };
        $scope.providerDetails = [
            {'name': ''},
            {'website': ''},
            {'logo': ''},
            {'bgImage': ''}
        ];

        $scope.init = function () {
            $scope.$on('toggleMarkerGrouping', function (event, data) {
                $scope.groupAgents = !$scope.groupAgents;
                toggleMarkerGrouping();
            });
            $scope.$on('switchMobileMoneyAgentsView', function (event, data) {
                $scope.mapView = !$scope.mapView;
            });
            $scope.$on('toggleFilters', function (event, data) {
                $scope.showFilters = !$scope.showFilters;
            });
            $scope.$on('enableSearch', function (event, data) {
                $scope.showSearchField = !$scope.showSearchField;
                if ($scope.showSearchField) {
                    $location.hash('search');
                    $anchorScroll();
                }
                $scope.searchWord = '';

            });

            $scope.$watch('searchWord', function () {
                if (!$scope.initPhase) {
                    getAgents();

                }

            });
            initProvider();
            $scope.initPhase = false;
        };

        function getAgents() {
            setPrepareFilters();
            $scope.markers = [];
            $scope.agentList = [];
            $scope.loading = true;

            mobileMoneyService.getAgents($scope.filters, {
                success: function (agentsFromServer) {
                    $scope.agentList = agentsFromServer.data;
                    if ($scope.mapView) {
                        angular.forEach(agentsFromServer.data, function (item, index) {
                            $scope.markers.push({
                                lat: item.latitude,
                                lng: item.longitude,
                                group: $scope.groupAgents ? 'mpesa' : null,
                                icon: {
                                    iconUrl: '/images/mobile-money/vodacom-marker.png',
                                    iconSize: [20, 30],
                                    popupAnchor: [0, -40], // point from which the popup should open relative to the iconAnchor [15, 0]
                                    iconAnchor: [10, 40], // point of the icon which will correspond to marker's location
                                    labelAnchor: [7, -30]

                                },
                                riseOnHover: true,
                                message: "<div class='style-primary-dark no-margin no-padding height-6' style='width:315px;' ng-controller='mobileMoneyCtrl'>" +
                                "<div class='stick-top-left' style='margin-bottom:25px;'>" +
                                "<span class=' label label-success text-uppercase' style=''>MASERU</span> " +
                                "</div>" +
                                "<div class='holder'>" +
                                "<div class='overlay text-center'>" +
                                "<span class=' text-xl text-shadow  text-uppercase col-xs-12'>" + item.name + "</span> <br/>" +
                                "<span class='text-primary-bright text-uppercase col-xs-12' ng-show='" + item.hasLocation + "'>" + item.location + "</span>" +


                                "<div  class='text-divider text-primary-bright margin-bottom-10' ng-show='" + item.hasArea + "'><span>Area</span></div>" +
                                "<div class='col-xs-12 text-center' style='height:25px;'> <span class='text-xl text-muted'>" + item.area + "</span>" +
                                "</div>" +

                                "<div class='text-divider text-primary-bright margin-bottom-10 margin-top-20'><span>Agent Details</span></div>" +

                                "<div class='col-xs-12' ng-show='" + item.hasCode + "'><span class='pull-left text-ultra-bold text-shadow  text-primary-bright'>Code</span> <span class='text-primary-bright text-lg pull-right'>" + item.code + "</span>" +
                                "</div>" +
                                "<div class='col-xs-12' ng-show='" + item.hasPhone + "'><span class='pull-left text-ultra-bold text-shadow  text-primary-bright'><i class='fa fa-phone'></i></span> <span class='text-primary-bright text-lg pull-right'>" + item.phone + "</span>" +
                                "</div>" +
                                "<div class='col-xs-12'><span class='pull-left text-ultra-bold text-shadow  text-primary-bright'>GPS</span> <span class='text-primary-bright text-lg pull-right'>" + item.latitude + ", " + item.longitude + "</span>" +
                                "</div>" +
                                "<div class='col-xs-12'><span class='pull-left text-ultra-bold text-shadow  text-primary-bright'><i class='fa fa-globe'></i></span> <a target='_blank' ng-href='http://www.vodacom.co.ls' class='label style-primary text-sm pull-right' style='color:#caf0ee;'>Visit Website</a>" +
                                "</div>" +

                                "</div>" +
                                "</div>" +
                                "<img class='img-responsive' src='./images/mobile-money/vodacom-bg.png'style='display: block;margin:auto' alt=''>" +

                                "<span ng-show='false' class='stick-bottom-right label label-success text-uppercase' style='margin-bottom:25px;'>" +
                                "<a style='color:#ffffff' href='./directions?end=-29.338678,27.461934'>Get Directions</a></span>" +
                                "</div>",
                                label: {
                                    "message": "<div class='text-center'>" +
                                    " <span  class='text-ultra-bold text-lg text-uppercase' style='width:10px'>" + item.name + "</span>" +
                                    " <br/><span class='text-capitalize'>" + item.location + "</span>" +
                                    " <br/><span class='text-capitalize'>" + item.area + "</span>" +
                                    "</div>"
                                }


                            });

                        });

                    }

                    $scope.loading = false;


                },
                error: function () {
                    $scope.loading = false;

                }
            });

        }

        $scope.updateDistrictSelection = function (selectedPosition) {
            angular.forEach($scope.lookups.districts, function (district, index) {
                    if (selectedPosition == index) {
                        district.selected = true;
                        $scope.selectedDistrict = district.name;
                    }
                    else {
                        district.selected = false;
                    }
                }
            )
            ;
            //Pull agents from the server after district change
            getAgents();
        };
        function initProvider() {
            var provider = '';
            if ($location.absUrl().indexOf('mpesa') > -1) {
                provider = 1;
                $scope.providerDetails.name = 'Vodacom Lesotho';
                $scope.providerDetails.website = 'http://www.vodacom.co.ls';
                $scope.providerDetails.logo = 'vodacom-logo.png';
                $scope.providerDetails.bgImage = 'vodacom-bg.png';
                $scope.lookups.districts[0].selected = true; //Select Berea By Default

            }
            else if ($location.absUrl().indexOf('ecocash') > -1) {
                provider = 2;
                $scope.providerDetails.name = 'Econet Telecom Lesotho';
                $scope.providerDetails.website = 'http://www.etl.co.ls';
                $scope.providerDetails.logo = 'econet-logo.png';
                $scope.providerDetails.bgImage = 'etl-bg.png';
                $scope.lookups.districts[4].selected = true; //Select Maseru By Default

            }
            else {
                provider = '';
            }
            $scope.filters.provider = provider;

        }

        function setPrepareFilters() {
            var selectedDistricts = [];
            angular.forEach($scope.lookups.districts, function (district, index) {

                if (district.selected) {
                    selectedDistricts.push(index + 1)
                }

            });

            $scope.filters.searchWord = $scope.searchWord;
            $scope.filters.districts = selectedDistricts;

        }

        function toggleMarkerGrouping() {
            $scope.loading = true;
            if ($scope.groupAgents) {
                angular.forEach($scope.markers, function (agent, index) {
                    agent.group = 'mpesa';
                });
            }
            else {
                angular.forEach($scope.markers, function (agent, index) {
                    agent.group = null;
                });
            }

            var markerCopy = $scope.markers;
            $scope.markers = [];
            setTimeout(function () {
                $scope.markers = markerCopy;
                $scope.loading = false;
                $scope.$apply();
            }, 500);

        }


    }

})
();
(function () {
    'use strict';

    angular
        .module('serviceboxApp')
        .controller('policeStationCtrl', policeStationCtrl);

    policeStationCtrl.$inject = ['$scope', 'contactsService', '$aside', '$location', 'helpersService'];
    function policeStationCtrl($scope, contactsService, $aside, $location, helpersService) {
        $scope.showFilters = true;
        $scope.mapView = true;
        $scope.policeStations = 0;
        $scope.policePosts = 0;
        $scope.lookups = {
            districts: [
                {'name': 'Berea', 'selected': true},
                {'name': 'Botha Bothe', 'selected': true},
                {'name': 'Leribe', 'selected': true},
                {'name': 'Mafeteng', 'selected': true},
                {'name': 'Maseru', 'selected': true},
                {'name': "Mohale's Hoek", 'selected': true},
                {'name': 'Mokhotlong', 'selected': true},
                {'name': "Qacha's Nek", 'selected': true},
                {'name': 'Quthing', 'selected': true},
                {'name': 'Thaba Tseka', 'selected': true}
            ],
            selectAllDistricts: true,
            types: [
                {'name': 'Police Stations', 'selected': true},
                {'name': 'Police Posts', 'selected': true},
                {'name': 'Headquarters', 'selected': true}
            ],
            selectAllTypes: true
        };
        $scope.filters = {
            types: [],
            districts: [],
            userLatLng: []
        };
        //DISTANCE SEARCH
        $scope.filterByDistance = false;
        $scope.radius = 30000; //Radius in METERS
        $scope.userLocation = {lat: -29.339659, lng: 27.468201};
        $scope.distance = {
            value: 5,
            options: {
                floor: 1,
                ceil: 10,
                step: 1,
                showTicks: true,
                selectionBarColor: '#242A31',
                // showTicksValues: true,
                translate: function (value) {
                    return value + 'km';
                }
            }
        };
        function setDistanceSearchRadius(radius) {
            $scope.radius = radius * 1000;// RADIUS IS IN METERS
            $scope.paths = {};
            $scope.paths = $scope.filterByDistance ? {
                circle: {
                    type: "circle",
                    radius: $scope.radius,
                    className: "searchRadius",
                    latlngs: {lat: $scope.userLocation.lat, lng: $scope.userLocation.lng}
                }
            } : null;
        }

        $scope.init = function () {
            $scope.$watch('distance.value', function (newRadius, oldRadius) {
                setDistanceSearchRadius(newRadius);
                getPoliceStationsFromServer();

            });
            $scope.$on('switchView', function (event, data) {
                $scope.mapView = !$scope.mapView;
            });
            $scope.$on('showDistrictFilterAside', function (event, data) {
                showDistrictFilterAside();

            });
            $scope.$on('showDistanceFilter', function (event, data) {

                $location.hash('distanceFilter');
                $scope.filterByDistance = !$scope.filterByDistance;
                $scope.filterByDistance ? setDistanceSearchRadius($scope.radius / 1000) : $scope.paths = {};
                getPoliceStationsFromServer();

            });
            $scope.$on('toggleView', function (event, data) {

            });
            $scope.$on('toggleFilters', function (event, data) {
                $scope.showFilters = !$scope.showFilters;
            });
            $scope.$watch('lookups.selectAllDistricts', function (newValue, oldValue) {
                if (oldValue != newValue) {
                    if (newValue) {
                        angular.forEach($scope.lookups.districts, function (district, index) {
                            district.selected = true;
                        });
                        getPoliceStationsFromServer();

                    }
                    else {
                        angular.forEach($scope.lookups.districts, function (district, index) {
                            district.selected = false;
                        });
                        $scope.policeStationList = [];
                        $scope.markers = [];
                        $scope.policeStations = 0;
                        $scope.policePosts = 0;
                    }
                }


            });
            getPoliceStationsFromServer();
           /* helpersService.getDeviceLocation({
                success:function(latLng){
                    $scope.userLocation.lat=latLng.lat;
                    $scope.userLocation.lng=latLng.lng;

                },
                error:function(){
                     console.log("Failed to get Location");
                }
            });*/

            $scope.initMode = false;

        };
        angular.extend($scope, {
            center: {
                lat: -29.494597,
                lng: 28.332367,
                zoom: 9

            },

            markers: [],
            paths: {},
            layers: {
                baselayers: {
                    googleRoadmap: {
                        name: 'Google Streets',
                        layerType: 'ROADMAP',
                        type: 'google'
                    },
                    googleHybrid: {
                        name: 'Google Hybrid',
                        layerType: 'HYBRID',
                        type: 'google'
                    }


                }
            }
        });

        $scope.getPoliceStations = function () {
            getPoliceStationsFromServer();
        };
        function setPrepareFilters() {
            var selectedDistricts = [];
            var selectedTypes = [];

            angular.forEach($scope.lookups.districts, function (district, index) {

                if (district.selected) {
                    selectedDistricts.push(index + 1)
                }

            });
            angular.forEach($scope.lookups.types, function (type, index) {

                if (type.selected) {
                    selectedTypes.push(index + 1)
                }

            });


            $scope.filters.types = selectedTypes;
            $scope.filters.districts = selectedDistricts;

            if ($scope.filterByDistance) {
                $scope.filters.userLatLng.push($scope.userLocation.lat, $scope.userLocation.lng);
            }
            else {
                $scope.filters.userLatLng = [];
            }

        }

        function getPoliceStationsFromServer() {

            setPrepareFilters();
            $scope.markers = [];
            $scope.policeStationList = [];
            $scope.policeStations = 0;
            $scope.policePosts = 0;
            $scope.loading = true;

            contactsService.getPoliceStations($scope.filters, {
                success: function (policeStationsFromServer) {
                    $scope.policeStationList = policeStationsFromServer.data;
                    if ($scope.filterByDistance) {
                        var filteredPoliceStationList = [];
                        angular.forEach($scope.policeStationList, function (station, index) {
                            var dist = helpersService.getDistanceBetweenPoints($scope.userLocation.lat, $scope.userLocation.lng, station.latitude, station.longitude, 'K').toFixed(2);
                            if (dist <= $scope.radius / 1000) { //RADIUS IS IN METERS
                                filteredPoliceStationList.push(station);
                            }

                        });
                        $scope.policeStationList = filteredPoliceStationList;

                    }

                    angular.forEach($scope.policeStationList, function (station, index) {


                        station.type.data.name == 'Police Post' ? $scope.policePosts++ : $scope.policeStations++;
                        $scope.markers.push({
                            lat: station.latitude,
                            lng: station.longitude,
                            //group: 'accommodation',
                            icon: {
                                iconUrl: '/images/police/' + station.icon,
                                iconSize: [20, 30],
                                popupAnchor: [0, -30], // point from which the popup should open relative to the iconAnchor [15, 0]
                                iconAnchor: [10, 30], // point of the icon which will correspond to marker's location
                                labelAnchor: [7, -30]

                            },
                            riseOnHover: true,
                            message: "<div ng-controller='bankCtrl' style='width:315px' class='style-primary-dark no-margin no-padding text- ng-scope'> " +
                            "<div style='margin-right: 30px;margin-top:7px' class='stick-top-left'><span class=' label label-info text-uppercase'>" + station.district.data.name + "</span>" +
                            "</div>" +
                            " <div class='holder'>" +
                            " <div class='overlay text-center'><span class='text-primary-bright text-lg text-bold text-uppercase'>" + station.name + "</span><br><br>" +
                            " <span class='text-lg text-shadow text-primary-bright'><i class='fa fa-phone'></i></span>" +
                            " <span class='text-bold text-lg text-primary-bright text-shadow'> " + station.phone + "</span>" +
                            " <div class='text-divider text-primary-bright margin-bottom-10'>" +
                            " <span>" +
                            "    <i class='fa fa-map-marker'></i>" +
                            "</span>" +
                            " </div>" +
                            " <div class='col-xs-12'>" +
                            "<span class='text-primary-bright text-shadow text-bold'>GPS </span>" +
                            "<span class='text-sm text-muted'>(Latitude/Longitude)</span> <br>" +
                            "<span class='text-ultra-bold text-primary-bright'>" + station.latitude.toFixed(4) + ", " + station.longitude.toFixed(4) + "</span></div>" +
                            "</div>" +
                            "</div>" +
                            "<img alt='' style='display: block;margin:auto;margin-top: 30px;' src='./images/police/bg.png' class='img-responsive'>" +
                            "<span style='margin-bottom: 25px;' class='stick-bottom-right label label-info ng-hide' ng-show='false'>" +
                            "<a href='./directions?end=-29.338678,27.461934' style='color:#ffffff'>Get Directions</a></span></div>"
                            ,
                            label: {
                                "message": "<div class='text-center'>" +
                                " <span class='text-ultra-bold text-lg' style='width:10px'>" + station.name + "</span><br/>" +
                                "</div>"
                            },
                            type: station.type.data


                        });

                    });
                    enlargeHeadquarterMarkers();
                    console.log("POLICE MARKERS...",$scope.markers);
                    $scope.loading = false;

                },
                error: function () {
                    $scope.loading = false;

                }
            });

        }

        function enlargeHeadquarterMarkers() {
            angular.forEach($scope.markers, function (station, index) {
                if (station.type.name == "Headquarters") {
                    station.icon.iconSize = [30, 43];
                    station.icon.iconAnchor = [10, 40];
                    station.icon.popupAnchor = [5, -40];
                }
            });
        }

        //MODALS AND ASIDES
        var districtFilterAside = $aside({
            scope: $scope,
            backdrop: false,
            templateUrl: '../app/police/templates/districtFilterAside.tpl.html',
            placement: 'right',
            container: 'body',
            animation: 'am-animation-slide-left',
            show: false
        });

        var showDistrictFilterAside = function () {
            districtFilterAside.$promise.then(districtFilterAside.show);
        };

        var filterByDistanceAside = $aside({
            scope: $scope,
            backdrop: false,
            templateUrl: '../app/police/templates/distanceFilterAside.tpl.html',
            placement: 'right',
            container: 'body',
            animation: 'am-animation-slide-left',
            show: false
        });
        var showFilterByDistanceAside = function () {
            filterByDistanceAside.$promise.then(filterByDistanceAside.show);
        };
    }

})();
(function () {
    'use strict';

    angular
        .module('serviceboxApp')
        .controller('telephoneTaxisCtrl', telephoneTaxisCtrl);

    telephoneTaxisCtrl.$inject = ['$scope', 'contactsService'];
    function telephoneTaxisCtrl($scope, contactsService) {

        $scope.loading = false;
        $scope.telephoneTaxis = [];
        $scope.loading = false;
        var filters = {
            districts: []
        };

        $scope.lookups = {
            districts: [
                {'name': 'Berea', 'selected': true},
                {'name': 'Botha Bothe', 'selected': true},
                {'name': 'Leribe', 'selected': true},
                {'name': 'Mafeteng', 'selected': true},
                {'name': 'Maseru', 'selected': true},
                {'name': "Mohale's Hoek", 'selected': true},
                {'name': 'Mokhotlong', 'selected': true},
                {'name': "Qacha's Nek", 'selected': true},
                {'name': 'Quthing', 'selected': true},
                {'name': 'Thaba Tseka', 'selected': true}
            ],
            selectAllDistricts: true
        };


        $scope.init = function () {
            getTelephoneTaxis();

        };

        function getTelephoneTaxis() {
            $scope.loading = true;
            setDistrictFilter();
            contactsService.getTelephoneTaxis(filters, {
                success: function (telephoneTaxisFromServer) {
                    $scope.telephoneTaxis = telephoneTaxisFromServer.data;
                    $scope.loading = false;

                },
                error: function () {
                    $scope.loading = false;
                }
            });
        }

        function setDistrictFilter() {
            var selectedDistricts=[];
            angular.forEach($scope.lookups.districts, function (district, index) {

                if (district.selected) {
                    selectedDistricts.push(index + 1)
                }

            });

           filters.districts = selectedDistricts;

        }
    }
})();
(function () {
    'use strict';

    angular
        .module('serviceboxApp')
        .controller('localBrandsCtrl', localBrandsCtrl);

    localBrandsCtrl.$inject = ['$scope','$location','localBrandsService'];
    function localBrandsCtrl($scope,$location,localBrandsService) {

        $scope.loading = false;
        $scope.brands = [];
        $scope.searchWord='';
        $scope.showSearchField=true;
        $scope.flags={
            contactsFlag:false,
            shopsFlag:false ,
            productsFlag:false,
            fullTextFlag:false
        };
        $scope.filters = {
            districts: []
        };

        $scope.lookups = {
            districts: [
                { 'name': 'Berea', 'selected': false },
                { 'name': 'Botha Bothe', 'selected': false },
                { 'name': 'Leribe', 'selected': false },
                { 'name': 'Mafeteng', 'selected': false },
                { 'name': 'Maseru', 'selected': false },
                { 'name': "Mohale's Hoek", 'selected': false },
                { 'name': 'Mokhotlong', 'selected': false },
                { 'name': "Qacha's Nek", 'selected': false },
                { 'name': 'Quthing', 'selected': false },
                { 'name': 'Thaba Tseka', 'selected': false }
            ],
            selectAllDistricts: true
        };
        $scope.lookups = {
            categories: [
                { 'name': 'Clothing', 'selected': false }
            ],
            selectAllDistricts: true
        };


        $scope.init = function () {
            $scope.searchWord=$location.path().split('/')[1];
            getLocalBrandsFromServer();

        };
        $scope.toggleSections=function(section){
            switch(section) {
                case 'contacts':
                   $scope.flags.shopsFlag=false;
                   $scope.flags.productsFlag=false;
                   $scope.flags.fullTextFlag=false;
                    $scope.flags.contactsFlag=!$scope.flags.contactsFlag;

                    break;
                case 'shops':
                    $scope.flags.contactsFlag=false;
                    $scope.flags.productsFlag=false;
                    $scope.flags.fullTextFlag=false;
                    $scope.flags.shopsFlag=!$scope.flags.shopsFlag;

                    break;
                case 'products':
                    $scope.flags.shopsFlag=false;
                    $scope.flags.contactsFlag=false;
                    $scope.flags.fullTextFlag=false;
                    $scope.flags.productsFlag=!$scope.flags.productsFlag;

                    break;
                case 'description':
                    $scope.flags.shopsFlag=false;
                    $scope.flags.contactsFlag=false;
                    $scope.flags.productsFlag=false;
                    $scope.flags.fullTextFlag=!$scope.flags.fullTextFlag;

                    break;
                default:
                    $scope.flags.contactsFlag=false;
                    $scope.flags.shopsFlag=false;
                    $scope.flags.productsFlag=false;
                    $scope.flags.fullTextFlag=false;
            }

        };
        function getLocalBrandsFromServer() {

            setDistrictFilter();
            $scope.brands = [];
            $scope.loading = true;

            localBrandsService.getLocalBrands($scope.filters, {
                success: function (brands) {
                    $scope.brands = brands.data;
                    $scope.loading = false;

                },
                error: function () {
                    $scope.loading = false;

                }
            });

        }
        function setDistrictFilter() {
            var selectedDistricts = [];
            var selectedCategories = [];
            
            angular.forEach($scope.lookups.districts, function (district, index) {

                if (district.selected) {
                    selectedDistricts.push(index + 1)
                }

            });
            angular.forEach($scope.lookups.categories, function (category, index) {

                if (category.selected) {
                    selectedCategories.push(index + 1)
                }

            });

            $scope.filters.districts = selectedDistricts;
            $scope.filters.categories=selectedCategories;

        }
    }
})();
(function () {
    'use strict';

    angular
        .module('serviceboxApp')
        .service('localBrandsService', localBrandsService);

    localBrandsService.$inject = ['$http', '$q'];
    function localBrandsService($http, $q) {
        var baseUri = "/api/",

            localBrandsService = {};

        localBrandsService.getLocalBrands = function (filters, callbacks) {
            var query = prepareLocalBrandsFilters(filters);
            var df = $q.defer();


            $http.get(baseUri + query, { cache: true }).success(function (responseData) {
                if (callbacks && callbacks.success) {
                    callbacks.success(responseData);
                }
                df.resolve(responseData);
            }).error(function () {
                if (callbacks && callbacks.error) {
                    callbacks.error("An error occurred while fetching  local brands");
                }
                df.reject();
            });
        };
        function prepareLocalBrandsFilters(filters) {
            var query = 'local-brands';

            if (filters && filters.districts) {
                if (query.length > 12) {
                    query += '&district=' + filters.districts;
                }
                else {
                    query += '?district=' + filters.districts;
                }


            }
            if (filters && filters.categories) {
                if (query.length > 12) {
                    query += '&category=' + filters.categories;
                }
                else {
                    query += '?category=' + filters.categories;
                }


            }

            return query;
        }

        return localBrandsService;
    }
})();
(function () {
    'use strict';
    angular.module('serviceboxApp').service('helpersService', helpersService);

    helpersService.$inject = [];
    function helpersService() {
        var Helpers = {};
        Helpers.getDeviceLocation = function (callback) {
            if (navigator.geolocation) {
                var options = {
                    enableHighAccuracy: true,
                    timeout: Infinity,
                    maximumAge: 5000 //1.5 Minutes
                };
                navigator.geolocation.getCurrentPosition(function (position) {

                    if (callback && callback.success) {
                        callback.success({lat: position.coords.latitude, lng: position.coords.longitude})
                    }

                }, function () {

                }, options);
            } else {

                console.log('Geolocation is not supported in your browser');


            }
        };
        Helpers.getDistanceBetweenPoints = function (lat1, lon1, lat2, lon2, unit) {
            var radlat1 = Math.PI * lat1 / 180;
            var radlat2 = Math.PI * lat2 / 180;
            var theta = lon1 - lon2;
            var radtheta = Math.PI * theta / 180;
            var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
            dist = Math.acos(dist);
            dist = dist * 180 / Math.PI;
            dist = dist * 60 * 1.1515;
            if (unit == "K") {
                dist = dist * 1.609344
            }
            if (unit == "N") {
                dist = dist * 0.8684
            }
            return dist
        };
        return Helpers;


    }


})();
(function () {
    'use strict';

    angular
        .module('serviceboxApp')
        .controller('outletsCtrl', outletsCtrl);

    outletsCtrl.$inject = ['$scope','telecomsOutletsService'];
    function outletsCtrl($scope,telecomsOutletsService) {

        $scope.loading = false;
        $scope.outlets = [];
        $scope.markers = [];
        $scope.searchWord='';
        $scope.mapView=true;
        $scope.showFilters=true;
        $scope.showSearchField=true;
        $scope.selectAllDistricts=true;
        $scope.flags={
            contactsFlag:false,
            shopsFlag:false ,
            productsFlag:false,
            fullTextFlag:false
        };
        $scope.filters = {
            districts: []
        };

        $scope.lookups = {
            districts: [
                { 'name': 'Berea', 'selected': true},
                { 'name': 'Botha Bothe', 'selected': true},
                { 'name': 'Leribe', 'selected': true},
                { 'name': 'Mafeteng', 'selected': true },
                { 'name': 'Maseru', 'selected': true },
                { 'name': "Mohale's Hoek", 'selected': true},
                { 'name': 'Mokhotlong', 'selected': true},
                { 'name': "Qacha's Nek", 'selected': true },
                { 'name': 'Quthing', 'selected': true},
                { 'name': 'Thaba Tseka', 'selected': true}
            ]

        };
        $scope.selectedDistrict=$scope.lookups.districts[4];

        angular.extend($scope, {
            center: {
                lat: -29.494597,
                lng: 28.332367,
                zoom: 8

            },

            markers: [],
            paths: {},
            layers: {
                baselayers: {
                    googleRoadmap: {
                        name: 'Google Streets',
                        layerType: 'ROADMAP',
                        type: 'google'
                    },
                    googleHybrid: {
                        name: 'Google Hybrid',
                        layerType: 'HYBRID',
                        type: 'google'
                    }


                }
            }
        });


        $scope.init = function () {
            getTelecomsOutletsFromServer();
        };
        $scope.$on('toggleFilters', function (event, data) {
            $scope.showFilters = !$scope.showFilters;
        });
        $scope.$on('switchView', function (event, data) {
            $scope.mapView = !$scope.mapView;
        });
        $scope.$watch('selectAllDistricts', function (newValue, oldValue) {
            if (oldValue != newValue) {
                if (newValue) {
                    angular.forEach($scope.lookups.districts, function (district, index) {
                        district.selected = true;
                    });
                    getTelecomsOutletsFromServer();

                }
                else {
                    angular.forEach($scope.lookups.districts, function (district, index) {
                        district.selected = false;
                    });
                    $scope.markers = [];
                    $scope.outlets = [];
                }
            }


        });
        $scope.updateDistrictSelection = function (selectedPosition) {
            getTelecomsOutletsFromServer();
        };
        function getTelecomsOutletsFromServer() {

            setDistrictFilter();
            $scope.outlets = [];
            $scope.loading = true;
            $scope.markers = [];

            telecomsOutletsService.getOutlets($scope.filters, {
                success: function (outlets) {

                    angular.forEach(outlets.data, function (item, index) {
                        var outlet_details = " <div class='row text-center' ng-show='" + item.phone + "'>" +
                            "<span class='col-xs-10 text-lg text-shadow text-center'><i class='fa fa-phone  fa-phone  text-success'></i> " + item.phone + "</span>" +
                        "</div> <hr class='ruler-lg' ng-show='" + item.phone + "'/>"+
                            " <div class='row text-center' ng-show='" + item.week_op_hrs + "'>" +
                            "<span class='col-xs-10 text-lg text-shadow text-left'> MON - FRI  " + item.week_op_hrs + "</span>" +
                            "</div> <hr class='ruler-lg' ng-show='" + item.week_op_hrs + "'/> "+

                            " <div class='row text-center' ng-show='" + item.sat_op_hrs + "'>" +
                            "<span class='col-xs-10 text-lg text-shadow text-left'> SAT  " + item.sat_op_hrs + "</span>" +
                            "</div> <hr class='ruler-lg' ng-show='" + item.sat_op_hrs + "'/>"+

                            " <div class='row text-center' ng-show='" + item.sun_op_hrs + "'>" +
                            "<span class='col-xs-10 text-lg text-shadow text-left'> SUN  " + item.sun_op_hrs + "</span>" +
                            "</div> <hr class='ruler-lg' ng-show='" + item.sun_op_hrs + "'/>";


                        $scope.markers.push({
                                lat: item.latitude,
                                lng: item.longitude,
                                icon: {
                                    iconUrl: setIconType(item.type),
                                    iconSize: [20, 20],
                                    popupAnchor: [10, -20], // point from which the popup should open relative to the iconAnchor [15, 0]
                                    iconAnchor: [0, 20], // point of the icon which will correspond to marker's location
                                    labelAnchor: [10, -15]

                                },
                                riseOnHover: true,
                                message: "<div class=' style-primary-dark no-margin no-padding' style='width:315px' ng-controller='outletsCtrl'>" +
                                " <div class='row' style='margin-left:2px;margin-right: 2px'>" +
                                "<h4 class=' text-center text-uppercase text-bold col-md-12'>" + item.name + "</h4>"
                                +outlet_details+
                                "</div>" +
                                "</div> ",
                                label: {"message": "<span class='text-ultra-bold text-lg' style='width:10px'>" + item.name + "</span><br/><span class='col-xs-12 text-center text-uppercase'>"+item.type +"</span>"
                                                    }

                            }
                        );

                    });



                    $scope.outlets = outlets.data;
                    $scope.loading = false;
                },
                error: function () {
                    $scope.loading = false;

                }
            });

        }
        function setIconType(type){
           if(type =="Shop")
               return '/images/econet/econet-shop.png';
           else if(type== "Container")
               return '/images/econet/econet-container.png';
           else
               return '/images/econet/econet-airtime.png';

        }
        function setDistrictFilter() {
            var selectedDistricts = [];

            angular.forEach($scope.lookups.districts, function (district, index) {

                if (district.selected) {
                    selectedDistricts.push(index + 1);
                }

            });

            $scope.filters.districts = selectedDistricts;

        }
    }
})();
(function () {
    'use strict';

    angular
        .module('serviceboxApp')
        .service('telecomsOutletsService', telecomsOutletsService);

    telecomsOutletsService.$inject = ['$http', '$q'];
    function telecomsOutletsService($http, $q) {
        var baseUri = "/api/",

            telecomsOutletsService = {};

        telecomsOutletsService.getOutlets = function (filters, callbacks) {
            console.log("UM iN SERVICES");
            var query = prepareOutletsFilters(filters);
            var df = $q.defer();


            $http.get(baseUri + query, { cache: true }).success(function (responseData) {
                if (callbacks && callbacks.success) {
                    callbacks.success(responseData);
                }
                df.resolve(responseData);
            }).error(function () {
                if (callbacks && callbacks.error) {
                    callbacks.error("An error occurred while fetching  outlets");
                }
                df.reject();
            });
        };
        function prepareOutletsFilters(filters) {
            var query = 'telecoms-outlets';

            if (filters && filters.districts) {
                if (query.length > 16) {
                    query += '&district=' + filters.districts;
                }
                else {
                    query += '?district=' + filters.districts;
                }


            }

            return query;
        }

        return telecomsOutletsService;
    }
})();